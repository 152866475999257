<template>
  <header id="header_id">
    <toolbar />

    <navbar />

    <v-btn
      fab
      dark
      tag="a"
      target="_blank"
      large
      color="green dark-1"
      fixed
      right
      bottom
      href="https://wa.me/5511985780621"
    >
      <v-icon
        dark
        large
        >fab fa-whatsapp</v-icon
      >
    </v-btn>

    <v-btn
      fab
      dark
      large
      color="primary"
      fixed
      right
      style="bottom: 90px"
      v-scroll="onScroll"
      v-show="fab"
      @click="toTop"
    >
      <v-icon dark>fas fa-caret-up</v-icon>
    </v-btn>
  </header>
</template>

<script>
export default {
  components: {
    Navbar: () => import('./Navbar'),
    Toolbar: () => import('./Toolbar'),
  },
  data: () => ({
    fab: false,
  }),
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>

<style></style>
